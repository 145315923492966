<template>
    <div id="register">
        <h1>
            Meine Daten ändern
        </h1>

        <form class="md-layout md-alignment-top-center" novalidate @submit.prevent="validateUser">
            <md-card class="md-layout-item md-large-size-50 md-small-size-90">
                <md-card-content>
                    <fieldset>
                        <legend>Persönliche Daten</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('firstName')">
                                    <label for="first-name">Vorname</label>
                                    <md-input id="first-name" v-model="form.firstName" :disabled="sending"
                                              autocomplete="given-name"
                                              name="first-name"/>
                                    <span v-if="!this.$v.form.firstName.required" class="md-error">
                                        Es muss ein Vorname angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.firstName.minLength" class="md-error">
                                        Der Vorname ist zu kurz!
                                    </span>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('lastName')">
                                    <label for="last-name">Nachname</label>
                                    <md-input id="last-name" v-model="form.lastName" :disabled="sending"
                                              autocomplete="family-name"
                                              name="last-name"/>
                                    <span v-if="!this.$v.form.lastName.required"
                                          class="md-error">
                                        Es muss ein Nachname angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.lastName.minLength" class="md-error">
                                        Der Nachname ist zu kurz!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Adresse</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('street')">
                                    <label for="street">Straße & Hausnummer</label>
                                    <md-input id="street" v-model="form.street" :disabled="sending"
                                              autocomplete="shipping street-address"
                                              name="street"/>
                                    <span v-if="!this.$v.form.street.required"
                                          class="md-error">
                                        Es muss eine Straße und eine Hausnummer angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.street.minLength" class="md-error">
                                        Ungültige Straße (mit Hausnummer)!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('zipCode')">
                                    <label for="zipCode">PLZ</label>
                                    <md-input id="zipCode" v-model="form.zipCode" :disabled="sending"
                                              autocomplete="shipping postal-code"
                                              name="zipCode"/>
                                    <span v-if="!this.$v.form.zipCode.required" class="md-error">
                                        Es muss eine PLZ angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.zipCode.minLength" class="md-error">
                                        Ungültige PLZ
                                    </span>
                                </md-field>
                            </div>

                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('city')">
                                    <label for="city">Stadt</label>
                                    <md-input id="city" v-model="form.city" :disabled="sending" autocomplete="address-level2"
                                              name="city"/>
                                    <span v-if="!this.$v.form.city.required" class="md-error">
                                        Es muss eine Stadt angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.city.minLength" class="md-error">
                                        Ungültige Stadt
                                    </span>
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Unternehmensdaten</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('companyName')">
                                    <label for="organization">Firmenname</label>
                                    <md-input id="organization" v-model="form.companyName" :disabled="sending"
                                              autocomplete="organization" name="organization"/>
                                    <span v-if="!this.$v.form.companyName.required"
                                          class="md-error">
                                        Es muss ein Firmenname angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.companyName.minLength"
                                          class="md-error">
                                        Der Firmenname ist zu kurz!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-small-size-100">
                                <md-field :class="getValidationClass('phoneNumber')">
                                    <label for="phoneNumber">Telefonnummer</label>
                                    <md-input id="phoneNumber" v-model="form.phoneNumber" :disabled="sending" autocomplete="tel"
                                              name="phoneNumber"/>
                                    <span v-if="!this.$v.form.phoneNumber.required" class="md-error">
                                        Es muss eine Telefonnummer angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.phoneNumber.minLength" class="md-error">
                                        Telefonnummer ungültig
                                    </span>
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Präqualifikation</legend>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-100">
                                <md-field :class="getValidationClass('ik')">
                                    <label for="ik">Institutionskennzeichen (IK)</label>
                                    <md-input id="ik" v-model="form.ik" :disabled="sending" autocomplete="off" name="ik"/>
                                    <span v-if="!this.$v.form.ik.required" class="md-error">
                                        Es muss ein IK angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.ik.ik_valid" class="md-error">
                                        Das IK ist nicht gültig!
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-50">
                                <md-field :class="getValidationClass('validSince')">
                                    <label>PQ gültig ab</label>
                                    <md-input v-model="form.validSince" type="date" :disabled="sending"
                                              autocomplete="off" />
                                    <span v-if="!this.$v.form.validSince.required" class="md-error">
                                        Es muss ein Gültigkeitsstart angegeben werden!
                                    </span>
                                </md-field>
                            </div>
                            <div class="md-layout-item md-size-50">
                                <md-field :class="getValidationClass('validUntil')">
                                    <label>PQ gültig bis</label>
                                    <md-input v-model="form.validUntil" type="date" :disabled="sending"
                                              autocomplete="off" />
                                    <span v-if="!this.$v.form.validUntil.required" class="md-error">
                                        Es muss ein Gültigkeitsende angegeben werden!
                                    </span>
                                    <span v-else-if="!this.$v.form.validUntil.inFuture" class="md-error">
                                        Das Datum muss in der Zukunft liegen.
                                    </span>
                                </md-field>
                            </div>
                        </div>
                        <div class="md-layout md-gutter">
                            <div class="md-layout-item md-size-100">
                                <md-field>
                                    <label for="treatments">Versorgungsbereiche</label>
                                    <md-input id="treatments" v-model="form.treatments" :disabled="sending" autocomplete="off"
                                              name="treatments"/>
                                </md-field>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset>
                        <legend>Partner-Newsletter</legend>
                        <div class="md-layout md-gutter">
                            <table class="md-layout-item md-size-100" style="text-align: left">
                                <tr>
                                    <td class="checkbox-col">
                                        <md-checkbox id="newsletter" v-model="form.newsletterAccepted" :class="getValidationClass('newsletter')"
                                                     :disabled="sending" autocomplete="off" name="newsletter">
                                        </md-checkbox>
                                    </td>
                                    <td>
                                        <label for="newsletter">
                                            Ich möchte gerne Newsletter (ca. 2 mal pro Jahr) bekommen.
                                        </label>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </fieldset>
                </md-card-content>

                <md-progress-bar v-if="sending" md-mode="indeterminate"/>

                <md-card-actions>
                    <div class="spacer"></div>
                    <md-button :disabled="sending" class="md-primary" to="/partner/my_data">Zurück</md-button>
                    <md-button :disabled="sending" class="md-primary md-raised" type="submit">Speichern</md-button>
                </md-card-actions>
            </md-card>
        </form>
    </div>
</template>

<script>
import {minLength, required} from 'vuelidate/lib/validators'
import {dateFormat} from "../helpers/dates";

/**
 * Validation of Institutionskennung
 * @param value
 * @returns {boolean}
 */
const ik_valid = (value) => {
    let val = "" + value;
    val = val.replace(/ /g, "")
    val = val.replace(/IK/g, "")
    val = val.replace(/[a-z]/g, "")
    val = val.replace(/[A-Z]/g, "")
    val = val.substr(2, 7)
    let sum = 0
    for (let i = 0; i < 6; i++) {
        let f1 = (i % 2 === 0) ? 2 : 1;
        let f2 = f1 * parseInt(val[i])
        let f3 = 0
        if (f2 >= 10) {
            f3 = f2 - 10 + 1;
        } else {
            f3 = f2;
        }
        sum += f3
    }
    sum = sum % 10;
    return sum === parseInt(val[6]);
}

const inFuture = (value) => {
    try {
        return (new Date()).getTime() < (new Date(value)).getTime()
    } catch (e) {
        return false
    }
}

export default {

    name: 'User_Data_edit',
    computed: {
        hint() {
            return "" + this.form.agb + " " + this.$v.form.agb.$invalid + " " + this.$v.form.agb.$error
        }
    },
    data: () => ({
        form: {
            // Personendaten
            firstName: "",
            lastName: "",
            //Address
            street: "",
            zipCode: "",
            city: "",
            // Firmendaten
            companyName: '',
            phoneNumber: '',
            // Präqualifikation
            ik: '',
            validSince: '',
            validUntil: '',
            regNumber: '',
            treatments: '',
            newsletterAccepted: false,
        },
        userSaved: false,
        sending: false,
        type: null
    }),
    mounted() {
        const t = this
        const i = setInterval(function(){ 
            if(t.$store.state.user){
                // Personal Data
                t.form.firstName = t.$store.state.user.firstName
                t.form.lastName = t.$store.state.user.lastName
                // Address
                t.form.street = t.$store.state.user.address.street
                t.form.zipCode = t.$store.state.user.address.postCode
                t.form.city = t.$store.state.user.address.city
                // Company
                t.form.companyName = t.$store.state.user.company.name
                t.form.phoneNumber = t.$store.state.user.company.phoneNumber
                t.form.ik = t.$store.state.user.company.ik
                t.form.validSince = dateFormat(t.$store.state.user.company.pq_since, "isoDate")
                t.form.validUntil = dateFormat(t.$store.state.user.company.pq_until, "isoDate")
                t.form.treatments = t.$store.state.user.company.serviceAreas
                t.form.newsletterAccepted = t.$store.state.user.newsletterAccepted

                clearInterval(i)
            }
            }, 10);
    },
    validations() {
        return {
            form: {
                firstName: {
                    required,
                    minLength: minLength(2)
                },
                lastName: {
                    required,
                    minLength: minLength(2)
                },
                street: {
                    required
                },
                zipCode: {
                    required
                },
                city: {
                    required
                },
                companyName: {
                    required
                },
                phoneNumber: {
                    required
                },
                ik: {
                    required,
                    ik_valid
                },
                validSince: {
                    required,
                },
                validUntil: {
                    required,
                    inFuture
                },
                newsletterAccepted: {
                },
            }
        }
    },
    methods: {
        getValidationClass(fieldName) {
            const field = this.$v.form[fieldName];

            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        clearForm() {
            this.$v.$reset();
            this.form = {}
        },
        validateUser() {
            this.$v.form.$touch();

            if (!this.$v.$invalid) {

                let form = this.form
                let req = {
                    id: this.$store.state.user.id,
                    firstName: form.firstName,
                    lastName: form.lastName,
                    address: {
                        street: form.street,
                        postCode: form.zipCode,
                        city: form.city,
                        country: "DE" // TODO Land einarbeiten und hier statischen Wert austauschen
                    },
                    newsletterAccepted: form.newsletterAccepted
                }

                req.company = {
                    name: form.companyName,
                    phoneNumber: form.phoneNumber,
                    ik: form.ik,
                    pq_since: new Date(form.validSince),
                    pq_until: new Date(form.validUntil),
                    serviceAreas: form.treatments,
                }

                this.$store.dispatch("editUserData", req).then(() => {
                    this.$v.$reset();
                    this.$router.push("/partner/my_data/")
                })
            } else {
                console.log("Vuelidation failed!")
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#register {
    margin: auto;
}

.spacer {
    flex-grow: 1;
}

.md-error {
    color: var(--md-theme-default-fieldvariant, #ff1744);
    font-size: 12px;
}
</style>
